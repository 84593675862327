import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Box } from '@mui/material';
import { MultilineText } from 'modules/picasso-ui/present/MultilineText';
import { useTranslation } from 'next-i18next';

const WallDialog = ({ open, title, message, onDismiss, 
  cancelButtonText,
  children, maxWidth, fullWidth, actionNotAdvised, dontWrapInContentText }) => {

  const { t } = useTranslation('common')

  //let confirmButtonTextEffective = confirmButtonText || t('action.confirm');

  const handleDismissClick = (e) => {
    onDismiss(e, 'cancelClicked');
  }



  const confirmBtnProps = {}

  if (actionNotAdvised) {
    confirmBtnProps.color = 'primary'
    confirmBtnProps.variant = 'contained'
    confirmBtnProps.style={backgroundColor: 'red'}
  } else {
    confirmBtnProps.color = 'primary'
    confirmBtnProps.variant = 'contained'
  }

  cancelButtonText = cancelButtonText || t('action.ok')

  const cancelBtnContainerProps = {}
  if (actionNotAdvised) {
    cancelBtnContainerProps.justifyContent = 'flex-start'
  } else {
    cancelBtnContainerProps.justifyContent = 'flex-end'
  }

  const cancelBtnProps = {}
  if (actionNotAdvised) {
    //cancelBtnProps.color = 'text'
    cancelBtnProps.variant = 'text'
  } else {
    //noop
  }

  const contentEffective = dontWrapInContentText === true ? 
      <>
        <MultilineText>{message}</MultilineText>
        {children}
      </>
      :
      <DialogContentText>
      <MultilineText>{message}</MultilineText>
      {children}
    </DialogContentText>

  return (
    <Dialog open={open} 
      onClose={onDismiss} 
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      >
      <DialogTitle sx={{fontSize: '20px'}}>{title}</DialogTitle>
      <DialogContent>
        {contentEffective}
      </DialogContent>
      <DialogActions>
          <Box flexGrow={1} {...cancelBtnContainerProps} display="flex">
            <Button {...cancelBtnProps} onClick={handleDismissClick}>{cancelButtonText}</Button>
          </Box>
{/*           <Button {...confirmBtnProps} onClick={onConfirm}>
            {confirmButtonTextEffective}
          </Button> */}
      </DialogActions>
    </Dialog>
  );
};

const WallDialogContext = React.createContext({});

const WallDialogProvider = ({ children }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogConfig, setDialogConfig] = React.useState({});

  const openDialog = ({ title, message, confirmButtonText, cancelButtonText, actionCallback, confirmDisabledDefault, actionNotAdvised }) => {
    setDialogOpen(true);
    setDialogConfig({ title, message, confirmButtonText, cancelButtonText, actionCallback, confirmDisabledDefault, actionNotAdvised });
  };

  const resetDialog = () => {
    setDialogOpen(false);
    //setDialogConfig({}); when this line is enabled, it causes a 'lag' (empty dialog visible for a few moments)
  };

  const onConfirm = () => {
    resetDialog();
    dialogConfig.actionCallback(true);
  };

  const onDismiss = () => {
    resetDialog();
    dialogConfig.actionCallback(false);
  };

  return (
    <WallDialogContext.Provider value={{ openDialog }}>
      <WallDialog
        open={dialogOpen}
        title={dialogConfig?.title}
        message={dialogConfig?.message}
        confirmButtonText={dialogConfig?.confirmButtonText}
        cancelButtonText={dialogConfig?.cancelButtonText}
        onConfirm={onConfirm}
        onDismiss={onDismiss}
        actionNotAdvised={dialogConfig?.actionNotAdvised}
      />
      {children}
    </WallDialogContext.Provider>
  );
};

const useWallDialog = () => {
  const { openDialog, setPayload } = React.useContext(WallDialogContext);

  const getWallDialog = ({ ...options }) => {
    return new Promise((res) => {
      openDialog({ actionCallback: res, ...options });
    });
  };

  return { getWallDialog, setPayload };
};

export default WallDialog;
export { WallDialogProvider, useWallDialog };
